import { useLayoutEffect, useRef } from 'react';
import { useStatefulRef } from '@core/hooks/useStatefulRef';
import { ensureIntersectionObserver } from '@core/polyfills';
/**
 * Used for detecting if object is within the viewport based on threshold.
 * Visibility change triggers callback `onChange`.
 * @returns Ref to be attached to the element to be observed.
 *
 * @example
 * const ref = useVisibilityObserver({
 *   onChange: useCallback((isVisible, target) => {
 *     if (isVisible) {
 *       // do something
 *     }
 *   }, []),
 * })
 *
 * return <div ref={ref}>Div to be measured</div>
 */
export function useVisibilityObserver({ externalRef, onChange, threshold = 0.7, }) {
    const observerRef = useRef(null);
    // Cannot use useRef here because the ref is not updated until the component
    // is re-rendered.
    const localContainerRef = useStatefulRef(null);
    const containerRef = externalRef || localContainerRef;
    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }
        observerRef.current?.disconnect();
        ensureIntersectionObserver().then((IO) => {
            const observer = new IO((entries) => {
                const entry = entries[0];
                const visible = entry.intersectionRatio >= threshold;
                if (containerRef.current) {
                    onChange?.(visible, containerRef.current, entry);
                }
            }, {
                threshold: [threshold],
            });
            observerRef.current = observer;
            if (containerRef.current) {
                observerRef.current.observe(containerRef.current);
            }
        });
        return () => {
            observerRef.current?.disconnect();
            observerRef.current = null;
        };
        // containerRef.current here triggers a rerender since it's a stateful ref,
        // hence it should be a dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef.current, onChange, threshold]);
    return containerRef;
}
