import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { css } from '@emotion/react';
import { useAdInFeed } from '@expPlatform/embeds/ads/hooks/useAdInFeed';
import { useAdsOnly } from '@expPlatform/embeds/ads/hooks/useAdsOnly';
import { putAdsToPositionInFeed } from '@expPlatform/embeds/ads/utils/putAdsToPositionInFeed';
import { useExternalVastXmlAds } from '@ads/hooks/useExternalVastXml';
import { useFeedQueryContext } from '@core/feed';
import { getVideoPoster } from '@core/helpers/video';
import { useVisibilityObserver } from '@core/hooks/useVisibilityObserver';
import { useContextTranslation } from '@core/i18n';
import { isInPIPIframe } from '@core/pictureInPicture';
import { PIP_DELIBERATELY_CLOSED } from '@core/pictureInPicture/constants';
import { PlayerLayout } from '@core/player/types';
import { parseShareLinkParams } from '@core/sharing';
import { useSessionStorage } from '@core/storage/hooks';
import { setPlayTrigger } from '@core/tracking/playTriggerTracking';
import { WidgetWrapper } from '@core/widgets';
import { useStore } from '@core/widgets/store';
import { appContextSelector, paramsSelector, } from '@core/widgets/store/selectors';
import { useTrackEmbedImpression, useTrackThumbnailImpression, } from '@core/widgets/tracking';
import { PlayerTitle } from '@embed/components/common/PlayerTitle';
import { AppContextType } from '@embed/components/helpers';
import { VideoPlayer } from '@embed/components/VideoPlayer';
import { usePlayerState } from '@embed/components/VideoPlayer/hooks/usePlayerState';
import { VideoPlayerOpenReason } from '@embed/components/VideoPlayer/types';
import { EventType } from '@embed/customEmbedEvent';
import { useDock } from '@embed/hooks/useDock';
import { GlobalCustomCSS } from '@embed/styles/GlobalCustomCSS';
import { useFetchSoftPIPPlayerState } from '@player/features/pictureInPicture/useFetchSoftPIPPlayerState';
import { STORYBLOCK, STORYBLOCK_HIDDEN } from '@player/testIds';
import { delegateVideoToPlayer } from './components/VideoPlayer/helpers';
import { useDispatchEmbedImpression } from './hooks/useDispatchEmbedImpression';
import { PlaySourceEnum } from './constants';
import { DockInfo, DockInfoMessage, FWNIframeClickOverlay, StoryblockContainer, StoryblockPlayer, VideoPoster, } from './Storyblock.styles';
const StoryblockBranding = lazy(() => import('@embed/StoryblockBranding'));
/**
 * Plain Storyblock component with videos
 */
export const StoryblockComponentWithVideos = (props) => {
    const { extraParams, videos: feedVideosFromProps } = props;
    const appContext = useStore(appContextSelector);
    const storedParams = useStore(paramsSelector);
    const params = useMemo(() => {
        return {
            ...storedParams,
            ...extraParams,
        };
    }, [storedParams, extraParams]);
    const trackEmbedImpression = useTrackEmbedImpression();
    const dispatchVideoEvent = useDispatchEmbedImpression();
    const trackThumbnailImpression = useTrackThumbnailImpression();
    const { t } = useContextTranslation();
    const isPinned = params.mode === 'pinned';
    const isFullscreen = params.mode === 'fullscreen';
    const [isPipDeliberatelyClosed] = useSessionStorage(PIP_DELIBERATELY_CLOSED, false);
    const isAdsOnly = useAdsOnly({ appContext });
    const externalAdsState = useExternalVastXmlAds(params);
    const { adVideos, positions, actions: adInFeedActions, } = useAdInFeed({
        params,
        appContext,
    });
    const [{ feed }, feedActions] = useFeedQueryContext();
    const videos = useMemo(() => {
        const ads = externalAdsState?.adVideos ?? adVideos;
        const adPositions = externalAdsState?.positions ?? positions;
        if (isAdsOnly || externalAdsState) {
            return ads;
        }
        return putAdsToPositionInFeed({
            adVideos: ads,
            videos: feedVideosFromProps,
            positions: adPositions,
        });
    }, [externalAdsState, adVideos, positions, isAdsOnly, feedVideosFromProps]);
    const [video, setVideo] = useState(null);
    const [wasClosed, setWasClosed] = useState(isPipDeliberatelyClosed);
    const hasPersistedPlayerState = Boolean(useFetchSoftPIPPlayerState());
    const [isDocked, dockActions] = useDock(params);
    const onVideoPlayerClose = useCallback((triggeredByUser) => {
        if (triggeredByUser) {
            dockActions.disableDock();
        }
        setWasClosed(true);
    }, [dockActions, setWasClosed]);
    const { isPlayingCurrentContent, currentPlayerId, currentPlayerLayout, canClaimPlayer, } = usePlayerState(appContext, params);
    const shouldNotAutoplay = isInPIPIframe() || params.autoplay === false || hasPersistedPlayerState;
    const eligibleForDock = !hasPersistedPlayerState &&
        !isInPIPIframe() &&
        params.dock &&
        canClaimPlayer(VideoPlayerOpenReason.STORYBLOCK_DOCK);
    const { current: isInitiallyEligibleForPinned } = useRef(!hasPersistedPlayerState &&
        !isInPIPIframe() &&
        params.mode === 'pinned' &&
        canClaimPlayer(VideoPlayerOpenReason.STORYBLOCK_CONFIG));
    const storyblockRef = useVisibilityObserver({
        onChange: useCallback((isVisible, element) => {
            if (shouldNotAutoplay || !eligibleForDock || isPinned) {
                return;
            }
            const isScrolledUnderTop = element.getBoundingClientRect().top < 0;
            if (!isVisible && isScrolledUnderTop) {
                dockActions.dock();
            }
            else if (isVisible && isDocked) {
                dockActions.undock();
            }
        }, [shouldNotAutoplay, eligibleForDock, isPinned, isDocked, dockActions]),
    });
    useEffect(() => {
        if (isDocked && !eligibleForDock && currentPlayerId !== params.widget_id) {
            dockActions.undock();
        }
    }, [dockActions, params, isDocked, eligibleForDock, currentPlayerId, videos]);
    const onNavigateToVideo = useCallback(({ video, navigationType, countOfNextVideos }) => {
        setPlayTrigger(params.widget_id, video.encoded_id, navigationType);
        setVideo(video);
        if (isAdsOnly) {
            adInFeedActions.loadMore();
        }
        else {
            feedActions.loadMore(countOfNextVideos);
        }
    }, [adInFeedActions, feedActions, isAdsOnly, params.widget_id]);
    const videoPoster = useMemo(() => getVideoPoster(video), [video]);
    const firstVideo = videos[0];
    useEffect(() => {
        setVideo(firstVideo);
    }, [firstVideo]);
    /** Send impression tracking based on Player visibility */
    const [shouldTriggerPlay, setTriggerPlay] = useState(false);
    const [shouldTriggerImpression, setTriggerImpression] = useState(false);
    const handlePlayerVisibilityChange = useCallback((intersectionRatio) => {
        setTriggerImpression((impressionTriggered) => {
            return impressionTriggered || intersectionRatio > 0.7;
        });
        setTriggerPlay((playTriggered) => {
            return playTriggered || intersectionRatio > 0.1;
        });
    }, []);
    useEffect(() => {
        if (shouldTriggerImpression && videos[0] && appContext) {
            trackEmbedImpression(appContext);
            dispatchVideoEvent({
                type: appContext.appContextType ===
                    AppContextType.EMBED_STORYBLOCK_CONTEXT_TYPE
                    ? EventType.FW_STORYBLOCK_IMPRESSION
                    : EventType.FW_PLAYER_IMPRESSION,
                video: videos[0],
            });
            trackThumbnailImpression([videos[0]], PlaySourceEnum.PLAYER, appContext);
        }
    }, [
        appContext,
        shouldTriggerImpression,
        videos,
        trackEmbedImpression,
        dispatchVideoEvent,
        trackThumbnailImpression,
    ]);
    const handleClickInFWNIframe = useCallback((e) => {
        if (delegateVideoToPlayer({
            video,
            appContext,
            params,
            videos,
            feed,
        })) {
            e?.preventDefault();
            e?.stopPropagation();
        }
    }, [video, videos, feed, appContext, params]);
    const initialLayout = useMemo(() => {
        if (isFullscreen) {
            return PlayerLayout.FULLSCREEN;
        }
        else if (isDocked || isPinned) {
            return PlayerLayout.MINIMIZED;
        }
        else {
            return PlayerLayout.DEFAULT;
        }
    }, [isFullscreen, isDocked, isPinned]);
    const reason = useMemo(() => {
        if (isFullscreen || isPinned) {
            return VideoPlayerOpenReason.STORYBLOCK_CONFIG;
        }
        else if (isDocked) {
            return VideoPlayerOpenReason.STORYBLOCK_DOCK;
        }
        else {
            return VideoPlayerOpenReason.NOT_OPENED;
        }
    }, [isFullscreen, isPinned, isDocked]);
    const forcePause = !shouldTriggerPlay ||
        (currentPlayerId !== null &&
            currentPlayerId !== params.widget_id &&
            (isPlayingCurrentContent ||
                currentPlayerLayout === PlayerLayout.FULLSCREEN));
    if ((isPinned || isFullscreen) && parseShareLinkParams(window)) {
        // Don not render pinned or fullscreen storyblock if share link is present
        return (<div style={{ display: 'none' }} data-testid={STORYBLOCK_HIDDEN}></div>);
    }
    // [CS-6333] This will not hit for any customer who's not using the vast_xml attribute, which we aren't advertising or promoting.
    // It should only affect Albertson, as this is a request of theirs
    if (externalAdsState?.loading) {
        return null;
    }
    const videoPlayer = (<VideoPlayer autoplay={!shouldNotAutoplay} isStoryblock={true} forcePause={forcePause} appContext={appContext} onClose={onVideoPlayerClose} params={params} pip={false} videos={videos} video={video} playerLayout={initialLayout} playerOpenReason={reason} onNavigateToVideo={onNavigateToVideo} onVisibilityChange={handlePlayerVisibilityChange}/>);
    return (<>
      <GlobalCustomCSS params={params} extra={isPinned
            ? css `
                width: 0 !important;
                height: 0 !important;
                min-height: 0 !important;
                min-width: 0 !important;
              `
            : undefined}/>
      <StoryblockContainer dir={params.dir} ref={storyblockRef} height={isPinned ? '0' : params.height ?? '0'} width={isPinned ? '0' : params.width ?? '0'} data-testid={STORYBLOCK} className={`cy-storyblock-${params.name || params.widget_id}`} onKeyUp={(e) => {
            /*
             * Prevent keyup events from propagating past our widgets to prevent interference
             * from other javascript on the embeding page causing unexpected behaviour.
             */
            e.stopPropagation();
        }}>
        {isPinned ? (isInitiallyEligibleForPinned && !wasClosed && videoPlayer) : (<>
            <StoryblockPlayer borderStyle={params.ui_border_style} branding={!!params.branding}>
              {videoPlayer}
              {(isPlayingCurrentContent || isDocked) && (<DockInfo>
                  <VideoPoster src={videoPoster} alt={t('Video Cover')}/>
                  <DockInfoMessage className="cy-video-in-pip-message">
                    <div>
                      <img src={require('@images/embed/picture-in-picture.svg')} alt={t('Picture in Picture icon')} role="presentation"/>
                      <div css={css `
                          width: 15em;
                        `}>
                        {t('Video is playing in picture in picture mode')}
                      </div>
                    </div>
                  </DockInfoMessage>
                </DockInfo>)}
              {isInPIPIframe() && (<FWNIframeClickOverlay onClick={handleClickInFWNIframe}/>)}
            </StoryblockPlayer>
            {!!params.branding && (<Suspense fallback={null}>
                <StoryblockBranding video={video}/>
              </Suspense>)}
          </>)}
      </StoryblockContainer>
    </>);
};
/**
 * Loads the feed videos and only renders the actual Storyblock if there are any videos
 */
export function StoryblockComponent() {
    const [{ videos, loading }] = useFeedQueryContext();
    const params = useStore(paramsSelector);
    const appContext = useStore(appContextSelector);
    const isAdsOnly = useAdsOnly({ appContext });
    // if it's pinned and doesnt have videos, don't render anything
    // regardless of loading state. It won't affect CLS anyway
    if (params.mode === 'pinned' && videos.length === 0 && !isAdsOnly) {
        return null;
    }
    // [CS-6333] if params.vast_xml is provided, it's okay for videos.length to be 0 and isAdsOnly to be false, we still want to show the SB
    const hasExternalVast = Boolean(params.vast_xml && params.vast_xml !== 'null');
    // If it's not pinned and it's done loading without videos, don't render anything
    if (!loading && videos.length === 0 && !isAdsOnly && !hasExternalVast) {
        return null;
    }
    return (<>
      {params.title && (<PlayerTitle className="cy-title" role="presentation" hasVideos={videos.length > 0} position={params.title_alignment} size={params.title_size}>
          {params.title}
        </PlayerTitle>)}
      <StoryblockComponentWithVideos videos={videos}/>
    </>);
}
/**
 * Top level Storyblock component
 */
export const Storyblock = ({ initialParams, widgetParams }) => (<WidgetWrapper appContextType={AppContextType.EMBED_STORYBLOCK_CONTEXT_TYPE} initialParams={initialParams} widgetParams={widgetParams}>
    <StoryblockComponent />
  </WidgetWrapper>);
